import { extractQueryParameter } from '../../utils/queryParameters';
import capitalize from 'lodash/capitalize';

export function getOpenerUrl() {
    const openerUrlEncoded = extractQueryParameter('opener');
    return openerUrlEncoded ? decodeURIComponent(openerUrlEncoded) : 'unknown';
}

export function gaLabelsForPartnerClick(partnerName: string) {
    const openerUrl = getOpenerUrl();

    return {
        'data-track-ga-event-trigger': 'click',
        'data-track-ga-event-category': 'contactForm',
        'data-track-ga-event-action': 'contactFormClicked',
        'data-track-ga-event-label': `partnerId::${partnerName}, openerUrl::${openerUrl}`,
    };
}

export function gaLabelsForPartnerView(partnerName: string) {
    const openerUrl = getOpenerUrl();

    return {
        'data-track-ga-event-trigger': 'visibility',
        'data-track-ga-event-category': 'contactForm',
        'data-track-ga-event-action': 'contactFormShown',
        'data-track-ga-event-label': `partnerId::${partnerName}, openerUrl::${openerUrl}`,
    };
}

export function gaLabelsForContactClick() {
    const openerUrl = getOpenerUrl();

    return {
        'data-track-ga-event-trigger': 'click',
        'data-track-ga-event-category': 'contactForm',
        'data-track-ga-event-action': 'contactFormClicked',
        'data-track-ga-event-label': `contact, openerUrl::${openerUrl}`,
    };
}

export function gaLabelsForContactView() {
    const openerUrl = getOpenerUrl();

    return {
        'data-track-ga-event-trigger': 'visibility',
        'data-track-ga-event-category': 'contactForm',
        'data-track-ga-event-action': 'contactFormShown',
        'data-track-ga-event-label': `contact, openerUrl::${openerUrl}`,
    };
}

export function gaLabelsForDiagnosisPage(trigger: 'click' | 'visibility') {
    return {
        'data-track-ga-event-trigger': trigger,
        'data-track-ga-event-category': 'contactForm',
        'data-track-ga-event-action': `diagnosisPageLink${capitalize(trigger)}`,
        'data-track-ga-event-label': `contact, openerUrl::${getOpenerUrl()}`,
    };
}
